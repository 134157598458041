import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatModule } from '../mat.module';
import { NgxModule } from '../ngx.module';
import { SocialSharedModule } from '../social-shared/social-shared.module';

// Shared Components


const sharedComponents = [

];

console.log('[FeedSharedModule:info] init');

@NgModule({
  declarations: [...sharedComponents],
  imports: [
    CommonModule,
    MatModule,
    NgxModule,
    SocialSharedModule
  ],
  exports: [...sharedComponents]
})
export class FeedSharedModule { }
